/* TestMode.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  
  .countdown-timer {
    font-size: 18px;
    font-weight: bold;
    color: #ff4757; /* Red color for urgency */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .time {
    font-size: 22px;
    color: #2ed573; /* Green color for modern look */
  }
  
  .question-block {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the buttons */
    word-wrap: break-word; /* Allow line breaks */
    white-space: pre-wrap; /* Preserve newlines */
  }


/* Responsive Design for Smartphones */
@media (max-width: 768px) {
    .question-block {
      max-width: 100%; /* Full width on smaller screens */
      margin: 10px auto; /* Slight margin adjustment */
      padding: 10px;
    }
  }
  
  .answer-button {
    display: block;
    width: 100%;
    max-width: 400px; /* Set a reasonable max-width for the buttons */
    padding: 12px;
    margin: 10px 0;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }
  
  .answer-button:hover {
    background-color: #f0f0f0;
  }
  
  .answer-button.selected {
    background-color: lightblue;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .answer-button {
      width: 90%; /* Make the buttons responsive for small screens */
      max-width: none;
      font-size: 14px; /* Reduce font size for smaller screens */
    }
  
    .submit-button {
      width: 90%; /* Ensure the submit button is also responsive */
    }
  }
  