/* App.css */

html, body {
  margin: 0;
  height: 100%; /* Ensure the body takes up the full height */
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}

.mode-switcher {
  text-align: center;
  margin-bottom: 20px;
}

.mode-switcher button {
  padding: 10px 20px;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  background-color: #007BFF;
  color: white;
  transition: background-color 0.3s ease;
}

.mode-switcher button:hover {
  background-color: #0056b3;
}

.category-selector {
  text-align: center;
  margin-bottom: 30px;
}

.category-button {
  padding: 15px 30px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.category-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.category-button.active {
  background-color: #007BFF;
  color: white;
  transform: scale(1.1);
}

main {
  flex: 1; /* Take up remaining space to push footer down */
}

/* Affiliate Links Section */
.affiliate-links {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
}

.affiliate-links h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.affiliate-links ul {
  text-align: justify;
  list-style-type: none;
  padding: 0;
}

.affiliate-links ul li {
  margin: 10px 0;
}

.affiliate-links a {
  color: #007bff;
  text-decoration: none;
}

.affiliate-links a:hover {
  text-decoration: underline;
}

/* Footer Styles */
.footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.footer p {
  margin: 0;
  color: #555;
  font-size: 14px;
}

/* Site Description */
.site-description {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}