/* TrainingMode.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
}

.question-block {
    max-width: 600px; /* Limit the width */
    margin: 20px auto; /* Center it on the page */
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    word-wrap: break-word; /* Allow line breaks */
    white-space: pre-wrap; /* Preserve newlines */
}


/* Responsive Design for Smartphones */
@media (max-width: 768px) {
    .question-block {
      max-width: 100%; /* Full width on smaller screens */
      margin: 10px auto; /* Slight margin adjustment */
      padding: 10px;
    }
  }

.question-block h2 {
    font-size: 18px; /* Adjust font size to match answers */
    text-align: justify;
}

.answer-button {
    display: block;
    width: 100%;
    max-width: 600px; /* Match the max-width of the question */
    padding: 12px;
    margin: 10px auto;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
}

.answer-button:hover {
    background-color: #f0f0f0;
}

.answer-button.correct {
    background-color: lightgreen;
}

.answer-button.incorrect {
    background-color: lightcoral;
}

.feedback {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.feedback-correct {
    color: green;
}

.feedback-incorrect {
    color: red;
}

/* Appendix Styling */
.appendix {
    max-width: 600px; /* Match the question width */
    margin: 20px auto;
    word-wrap: break-word; /* Allow line breaks */
    white-space: pre-wrap; /* Preserve newlines */
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    color: #666;
    text-align: justify;
}

/* Next Button (smaller like other buttons) */
.next-button {
    display: inline-block; /* Ensure it doesn't take full width */
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

.next-button:hover {
    background-color: #0056b3;
}
