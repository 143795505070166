body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
}

button {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: lightgray;
}

h1 {
  color: #333;
}
